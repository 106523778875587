@tailwind base;
@tailwind components;

@font-face {
  font-family: 'CenturyGothic';
  font-style: normal;
  src: url('/fonts/CenturyGothic.ttf');
  font-display: swap;
}

body {
  margin: 0;
  font-family: CenturyGothic, sans-serif;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  height: 100vh;
}

input:focus {
  outline: none;
}

.btnHeight {
  height: 3rem;
}

#nprogress .bar {
  background: #f76a18 !important;
}

#nprogress .spinner .spinner-icon {
  border-top-color: #f76a18;
  border-left-color: #f76a18;
}

.hero {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.65) 100%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url('/images/ANET_Hero.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.transition-all {
  transition: transform 0.3s ease-in-out;
}

.transition-all a {
  transition: color 0.3s linear;
}

.notActive {
  transform: translate(-100%, 0);
}

.active {
  transform: translate(0, 0);
}

.rotateDiv {
  transform: rotate(4.99deg);
}
.react-datepicker-wrapper {
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  padding: 0.7rem 0.5rem;
}

.react-datepicker__input-container input {
  width: 100%;
}

.newsBg {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.54) 100%,
      rgba(0, 0, 0, 0.54) 100%
    ),
    url('/images/beach.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#search {
  background: transparent;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 0s 50000s;
}

.newsletter {
  background-image: linear-gradient(
      rgba(162, 194, 202, 0.65) 100%,
      rgba(162, 194, 202, 0.65) 100%
    ),
    url('/images/lighted_road.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card {
  box-shadow: 0px 2px 24px rgba(56, 22, 2, 0.14);
  border-radius: 16px;
}

.servicesCard {
  box-shadow: 0px 2px 24px rgba(56, 22, 2, 0.14);
  border-radius: 16px;
}

.flutterwave {
  background-image: url('/images/flutterwave.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

textarea {
  resize: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus,
  select,
  textarea,
  input {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .notActive {
    transform: translate(0, 0);
  }
}

@tailwind utilities;
