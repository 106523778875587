#bus-layout {
  width: 300px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/icons/ANET_BUS_SHAPE.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.seat-row {
  --col-start: 1;
  --row-start: 1;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin: 10px 10px;
  grid-column-start: var(--col-start);
  grid-column-end: span 1;
  grid-row-start: var(--row-start);
  grid-row-end: span 1;
}

.seat-row.booked svg {
  fill: #f76a18;
}

.seat-row.selected svg {
  fill: #adf508;
}

.seat-row.available:hover {
  cursor: pointer;
}

@media screen and (min-width: 820px) {
   .seat-row {
    height: 25px;
    width: 25px;
    margin: 10px;
  }
    #bus-layout {
    width: 300px;
    height: 200px;
  }
}

@media screen and (min-width: 992px) {
  .seat-row {
    height: 45px;
    width: 45px;
    margin: 15px;
  }
  #bus-layout {
    width: 500px;
    height: 300px;
  }
}
